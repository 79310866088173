<template>
  <vue-modal
    :modal-title="$t('pages.permission.data')"
    :showSpinner="isLoading"
    :showModal="showModal"
    :showPrimaryFooterButton="false"
    :showSecondaryFooterButton="false"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div v-if="vacation">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="label-description">{{ $t("pages.permission.status") }}</label>
              <div>
                <vacation-confirm-waiting-day-legend v-if="vacation.isVacationsConfirmWaiting()" />
                <vacations-approved-day-legend v-if="vacation.isVacationsApproved()" />
                <vacations-need-meeting-day-legend v-if="vacation.isVacationsNeedMeeting()" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.vacations.start_date')"
              :value="vacation.dateStart"
            >
              <template #default="props">
                <formatted-date-component :val="props.value" />
              </template>
            </suc-text-form-show-field>
          </div>
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.vacations.end_date')"
              :value="vacation.dateEnd"
            >
              <template #default="props">
                <formatted-date-component :val="props.value" />
              </template>
            </suc-text-form-show-field>
          </div>
        </div>
        <div class="row" v-if="vacation.comments">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.vacations.comments')"
              :value="vacation.comments"
            />
          </div>
        </div>

        <div class="row" v-if="vacation.canRemove()">
          <div class="col-md-12 text-right">
            <vue-button
              class="btn-danger button my-1 mx-2"
              :title="$t('components.actions.remove')"
              @click.prevent="removeVacation"
            >
              <template #body-icon>
                <i class="fas fa-trash-alt"></i>
              </template>
            </vue-button>
          </div>
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import { SucTextFormShowField, VueButton } from "@/components/form";
import { ModalAlert } from "@/components/modal";
import {
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
} from "@/pages/components/day-legend";
import * as Sentry from "@sentry/vue";

import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import { VACATION_CLICKED_EVENT, VACATION_UPDATED_EVENT } from "./index";

import api from "@/api/appointment";
import { FormattedDateComponent } from "@/components/formatted";

export default {
  components: {
    VueModal,
    VueButton,
    SucTextFormShowField,
    FormattedDateComponent,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      vacation: null,
    };
  },
  computed: {
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  created() {
    EventBus.listen(VACATION_CLICKED_EVENT, (vacation) => {
      this.vacation = vacation;
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off(VACATION_CLICKED_EVENT);
  },
  methods: {
    init() {},
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.hourValue = "";
    },
    removeVacation() {
      if (this.vacation.canRemove()) {
        ModalAlert.confirm({
          message: this.$t("pages.permission.delete_confirmation"),
          confirmButtonText: this.$t("components.actions.confirm"),
          cancelButtonText: this.$t("components.actions.close"),
        }).then(async (response) => {
          if (response.isConfirmed) {
            api
              .removeAppointment(this.vacation.id)
              .then(() => {
                this.showModal = false;
                this.$toasts.success(this.$t("components.messages.success"));
                EventBus.fire(VACATION_UPDATED_EVENT);
                this.onCloseButtonClicked();
              })
              .catch((error) => {
                Sentry.captureException(error);
                let errorMessage = this.$t("components.messages.error");
                if (error?.response?.data?.errors) {
                  errorMessage = error.response.data.errors[""][0];
                }
                console.error(errorMessage);
                this.$toasts.error(errorMessage);
              });
          }
        });
      }
    },
  },
};
</script>
