<template>
  <div>
    <permission-list-search-component class="mb-4" />

    <permission-list-component />
  </div>
</template>

<script>
import { PermissionListSearchComponent, PermissionListComponent } from "./components";

export default {
  components: {
    PermissionListSearchComponent,
    PermissionListComponent,
  },
};
</script>

<style></style>
