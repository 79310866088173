<template>
  <div>
    <div class="row">
      <div class="col">
        <span class="search-header">{{ $t("pages.payroll.searching_header") }}</span>
        <img src="@/assets/IC_Minimise.png" alt="min" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <form>
          <div class="form-row">
            <div class="col-md-6">
              <appointment-status-field id="status" name="status" :form="form" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <a href="#" class="mx-4 search-clean" @click.prevent="clear">{{
          $t("components.actions.clear")
        }}</a>
        <vue-button
          :title="$t('components.actions.search')"
          class="btn-primary search-button"
          @click="searchClicked"
        ></vue-button>
      </div>
    </div>
  </div>
</template>

<script>
import { AppointmentStatusField } from "@/pages/components/fields";
import { VueButton, Form, Options } from "@/components/form";

import { SUC_SELECT_CHANGED_EVENT } from "@/components/form";
import { PERMISSION_SEARCH_CHANGED_EVENT } from "./index";

export default {
  components: {
    VueButton,
    AppointmentStatusField,
  },
  data() {
    return {
      form: new Form({
        data: {
          status: null,
          employeeCode: null,
        },
        options: {
          ...new Options().add("status", "id"),
        },
      }),
    };
  },
  computed: {
    selectChangeEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
  },
  methods: {
    init() {},
    clear() {
      this.form.reset();
      EventBus.fire(PERMISSION_SEARCH_CHANGED_EVENT, { status: null, employeeCode: null });
    },
    searchClicked() {
      let data = this.form.data();
      EventBus.fire(PERMISSION_SEARCH_CHANGED_EVENT, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.payroll-action {
  padding-left: 5px;
  color: $color-primary;

  & > img {
    margin-right: 5px;
  }
}

.payroll-searching {
  .search-header {
    color: $color-secondary;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-right: 5px;
  }

  .search-button {
    border-radius: $border-radius;
    height: 50px;
    width: 125px;
  }

  .search-clean {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
}
</style>
