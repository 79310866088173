<template>
  <div>
    <div class="d-flex p-2 table-responsive">
      <absence-justified-widget />
      <absence-confirm-waiting-widget class="ml-4" />
      <hour-balance-widget class="ml-4" v-if="activeHoursPack" />
    </div>

    <info-component
      :type="infoType"
      class="mt-5"
      :message="$t('pages.permission.absences.info_message')"
    ></info-component>

    <div class="row mt-5">
      <div class="col-sm-12 col-md-7">
        <absence-calendar-component></absence-calendar-component>
      </div>
      <div class="col-sm-12 col-md-5">
        <absence-create-form-component v-if="absenceCreate"></absence-create-form-component>
        <absence-edit-form-component
          v-if="absenceEdit"
          :absence="absence"
        ></absence-edit-form-component>

        <absence-show-modal />
      </div>
    </div>
  </div>
</template>

<script>
import {
  AbsenceJustifiedWidget,
  AbsenceConfirmWaitingWidget,
} from "@/pages/employee/permission/absences/widgets";
import { HourBalanceWidget } from "@/pages/employee/planning/widgets";

import InfoComponent, { INFO_TYPE_INFO_COMPONENT } from "@/pages/components/info";
import { ABSENCE_SHOW_CREATE_EVENT, ABSENCE_SHOW_EDIT_EVENT } from "./components";

import { mapGetters } from "vuex";

import {
  AbsenceCalendarComponent,
  AbsenceCreateFormComponent,
  AbsenceShowModal,
} from "@/pages/employee/permission/absences/components";

export default {
  components: {
    AbsenceCalendarComponent,
    InfoComponent,
    AbsenceJustifiedWidget,
    AbsenceConfirmWaitingWidget,
    HourBalanceWidget,
    AbsenceCreateFormComponent,
    AbsenceShowModal,
  },
  created() {
    EventBus.listen(ABSENCE_SHOW_EDIT_EVENT, (absence) => {
      this.absence = absence;
      this.showEdit();
    });
    EventBus.listen(ABSENCE_SHOW_CREATE_EVENT, (absence) => {
      this.absence = absence;
      this.showCreate();
    });
  },
  beforeDestroy() {
    EventBus.off([ABSENCE_SHOW_EDIT_EVENT, ABSENCE_SHOW_CREATE_EVENT]);
  },
  data() {
    return {
      absence: null,
      absenceEdit: false,
      absenceCreate: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack"]),
    infoType() {
      return INFO_TYPE_INFO_COMPONENT;
    },
  },
  methods: {
    showCreate() {
      this.absenceEdit = false;
      this.absenceCreate = true;
    },
    showEdit() {
      this.absenceEdit = true;
      this.absenceCreate = false;
    },
  },
};
</script>

<style></style>
