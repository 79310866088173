<template>
  <div class="cell-body">
    <template v-if="isEmpty">
      <span class="padding"></span>
    </template>
    <template v-else-if="isOne">
      <vacations-approved-day-legend
        :withTitle="false"
        v-if="hasApproved"
        class="padding"
        @click="appointmentClicked(cell.data[0])"
      />
      <vacation-confirm-waiting-day-legend
        :withTitle="false"
        class="padding"
        @click="appointmentClicked(cell.data[0])"
        v-else-if="hasWaitingConfirmation"
      />
      <vacations-need-meeting-day-legend
        :withTitle="false"
        class="padding"
        @click="appointmentClicked(cell.data[0])"
        v-else-if="hasMeeting"
      />
      <vacations-rejected-day-legend
        :withTitle="false"
        class="padding"
        @click="appointmentClicked(cell.data[0])"
        v-else-if="hasRejected"
      />
    </template>

    <template v-else>
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src="@/assets/IC_Vacaciones_Pendiente.png" alt="type" />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            class="dropdown-item"
            href="#"
            v-for="item in items"
            :key="item.key"
            @click.prevent="appointmentClicked(item)"
          >
            {{ permissionId(item) }} {{ pemissionStatus(item) }} (
            <formatted-date-component :val="item.dateStart" /> -
            <formatted-date-component :val="item.dateEnd" /> )
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CalendarCell } from "@/components/calendar";
import { FormattedDateComponent } from "@/components/formatted";

import { VACATION_CLICKED_EVENT } from "./index";
import {
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  VacationsRejectedDayLegend,
} from "@/pages/components/day-legend";

export default {
  name: "VacationContentComponent",
  components: {
    FormattedDateComponent,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
    VacationsRejectedDayLegend,
  },
  props: {
    cell: {
      type: CalendarCell,
      required: true,
    },
  },
  computed: {
    isEmpty() {
      return this.cell.isEmpty;
    },
    isOne() {
      return this.cell.data.length == 1;
    },
    hasApproved() {
      return this.$isset(this.cell.data.find((x) => x.isVacationsApproved()));
    },
    hasWaitingConfirmation() {
      return this.$isset(this.cell.data.find((x) => x.isVacationsConfirmWaiting()));
    },
    hasMeeting() {
      return this.$isset(this.cell.data.find((x) => x.isVacationsNeedMeeting()));
    },
    hasRejected() {
      return this.$isset(this.cell.data.find((x) => x.isVacationsRejected()));
    },
    items() {
      return this.cell.data;
    },
  },
  methods: {
    appointmentClicked(absence) {
      this.$emit(VACATION_CLICKED_EVENT, absence);
      EventBus.fire(VACATION_CLICKED_EVENT, absence);
    },
    pemissionStatus(vacation) {
      if (vacation.isVacationsRejected()) return this.$t("pages.permission.vacations.rejected");
      else if (vacation.isVacationsNeedMeeting())
        return this.$t("pages.permission.vacations.need_meeting");
      else if (vacation.isVacationsApproved())
        return this.$t("pages.permission.vacations.approved");
      else return this.$t("pages.permission.vacations.confirm_waiting");
    },
    permissionId(item) {
      return item.isPermission() ? `#${item.id}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  padding: 8px;
}
.cell-body {
  min-width: 63px;
  min-height: 40px;
}
</style>
>
