<template>
  <article>
    <h2>{{ $t("pages.permission.data") }}</h2>
    <form class="permission-form mt-3">
      <suc-form-has-error :form="form" />

      <div class="row">
        <div class="col">
          <suc-text-form-show-field
            :title="$t('pages.permission.vacations.id')"
            :value="vacation.id"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <vacation-start-day-field
            id="vacation-start-date"
            name="dateStart"
            :form="form"
            :required="true"
          />
        </div>
        <div class="col-sm-12 col-md-6">
          <vacation-end-day-field
            id="vacation-end-date"
            name="dateEnd"
            :form="form"
            :required="true"
          />
        </div>
      </div>
      <!-- <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="title" for="last_day">Horrario</label>
            <br />

            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="customRadioInline1"
                name="customRadioInline1"
                class="custom-control-input checkmark"
              />
              <label class="custom-control-label" for="customRadioInline1">Todo el día</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="customRadioInline2"
                name="customRadioInline1"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="customRadioInline2">Medio día</label>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col">
          <vacation-comment-field id="vacation-comment" name="comments" :form="form" />
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          <vue-button
            :title="$t('pages.permission.vacations.send_request')"
            class="btn-primary send-button"
            :loading="isLoading"
            @click="sendRequest"
          />
        </div>
      </div>
    </form>

    <success-info-modal-component />
  </article>
</template>

<script>
import { SucFormHasError, Form, VueButton, SucTextFormShowField } from "@/components/form";
import { RequiredRule, FormRule } from "@/components/form/data/rules";

import { SuccessInfoModalComponent, SHOW_SUCCESS_INFO_MODAL } from "@/pages/components";
import {
  VacationStartDayField,
  VacationEndDayField,
  VacationCommentField,
} from "@/pages/employee/permission/vacations/fields";

import api from "@/api/appointment";
import { FinalAbsence } from "@/pages/components/data";
import { isAfter, isEqual } from "date-fns";

import { VACATION_UPDATED_EVENT } from "./index";

export default {
  props: {
    vacation: {
      type: FinalAbsence,
      required: true,
    },
  },
  components: {
    SuccessInfoModalComponent,
    VacationStartDayField,
    VacationEndDayField,
    VacationCommentField,
    VueButton,
    SucTextFormShowField,
    SucFormHasError,
  },
  data() {
    return {
      isLoading: false,
      form: new Form({
        data: {
          dateStart: this.vacation.dateStart,
          dateEnd: this.vacation.dateEnd,
          comments: this.vacation.comments,
          typeAppointment: "VACACIONES",
        },
      }),
    };
  },
  computed: {
    fieldsRules() {
      return [
        new RequiredRule({
          name: "dateStart",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
        new RequiredRule({
          name: "dateEnd",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];
    },
    formRules() {
      let checkDate = new FormRule({
        rule: (x) => {
          return isAfter(x.dateEnd, x.dateStart) || isEqual(x.dateEnd, x.dateStart);
        },
        errorMessage: this.$t("pages.permission.wrong_period_date"),
      });
      return [checkDate];
    },
  },
  methods: {
    clear() {
      this.form.reset();
    },
    sendRequest() {
      this.form.setFieldsRules(this.fieldsRules);
      this.form.setFormRules(this.formRules);
      if (this.form.validate()) {
        alert(2);
        let data = this.form.data();
        data["key"] = this.vacation.key;
        this.isLoading = true;
        api
          .editAppointment(this.vacation.id, data)
          .then(() => {
            EventBus.fire(SHOW_SUCCESS_INFO_MODAL, {
              message: this.$t("pages.permission.vacations.operation_completed"),
            });
            EventBus.fire(VACATION_UPDATED_EVENT);
            this.clear();
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.send-button {
  border-radius: $border-radius;
  height: 50px;
}
</style>
