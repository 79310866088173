<template>
  <div>
    <card-info-component
      :title="$t('pages.permission.vacations.approved')"
      :value="value"
      :isLoading="isLoading"
      @[valueClickedEvent]="valueClicked"
    >
      <template v-slot:value="props">
        <a
          class="card-text"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ props.value }}
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <div class="dropdown-item media h-25" v-for="item in items" :key="item.id">
            <div class="media-body p-1">
              <div class="text-sm p-1">
                <i class="far fa-clock notification-icon"></i>
                <span class="pl-2">
                  <formatted-date-component :val="item.dateStart" :withTime="true" />
                  -
                  <formatted-date-component :val="item.dateEnd" :withTime="true" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </card-info-component>
  </div>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";
import { FormattedDateComponent } from "@/components/formatted";

import api from "@/api/appointment";
import { FinalAbsence } from "@/pages/components/data";

export default {
  components: {
    CardInfoComponent,
    FormattedDateComponent,
  },
  data() {
    return {
      items: [],
      raw: [],
      start: null,
      end: null,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    value() {
      return this.items.length;
    },
    valueClickedEvent() {
      return "value-clicked-event";
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.start = new Date(new Date().getFullYear(), 1, 1);
      this.end = new Date(new Date().getFullYear(), 12, 31);

      const { data } = await api.getMyVacations({ start: this.start, end: this.end });
      this.items = data
        .map((x) => new FinalAbsence().parse(x))
        .filter((x) => x.isVacationsApproved());
      this.raw = data;

      this.isLoading = false;
    },
    valueClicked(value) {
      console.log(value);
    },
  },
};
</script>

<style scoped></style>
