import VacationCalendarComponent from "@/pages/employee/permission/vacations/components/VacationCalendarComponent.vue";
import VacationCreateFormComponent from "@/pages/employee/permission/vacations/components/VacationCreateFormComponent.vue";
import VacationEditFormComponent from "@/pages/employee/permission/vacations/components/VacationEditFormComponent.vue";
import VacationShowModal from "@/pages/employee/permission/vacations/components/VacationShowModal.vue";

const VACATION_CLICKED_EVENT = "vacation-clicked-event";
const VACATION_SHOW_EDIT_EVENT = "vacation-show-edit-event";
const VACATION_SHOW_CREATE_EVENT = "vacation-show-create-event";
const VACATION_SHOW_CONFIRM_DELETE_EVENT = "vacation-show-confirm-delete-event";

const VACATION_CREATED_EVENT = "vacation-created-event";
const VACATION_UPDATED_EVENT = "vacation-updated-event";

export {
  VACATION_CLICKED_EVENT,
  VACATION_SHOW_EDIT_EVENT,
  VACATION_SHOW_CREATE_EVENT,
  VACATION_CREATED_EVENT,
  VACATION_UPDATED_EVENT,
  VACATION_SHOW_CONFIRM_DELETE_EVENT,
  VacationShowModal,
  VacationCalendarComponent,
  VacationCreateFormComponent,
  VacationEditFormComponent,
};
