<template>
  <div role="main" class="container" v-if="activeEmployeePermissions">
    <article>
      <h1 class="mb-4">{{ $t("pages.permission.title") }}</h1>

      <card-data-component :tabs="tabs" :currentTabKey="tabKey" @[tabChangedEvent]="tabChanged">
        <template #data="props">
          <vacation-tab v-if="isVacation(props.tab.key)" />
          <absence-tab v-if="isAbsence(props.tab.key)" />
          <permission-list-tab v-if="isPermissionTab(props.tab.key)" />
        </template>
      </card-data-component>
    </article>
  </div>
</template>

<script>
import VacationTab from "@/pages/employee/permission/vacations/VacationTab.vue";
import AbsenceTab from "@/pages/employee/permission/absences/AbsenceTab.vue";
import PermissionListTab from "@/pages/employee/permission/permission-list/PermissionListTab.vue";
import CardDataComponent, { Tab, CARD_DATA_TAB_CHANGED } from "@/pages/components/card-data";

import {
  PERMISSION_ABSENCE_TAB,
  PERMISSION_VACATION_TAB,
  PERMISSION_LIST_TAB,
} from "@/pages/employee/employee-constants";

import { WhoAmIMixin } from "@/components/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [WhoAmIMixin],
  layout: "MainLayout",
  middleware: ["auth", "notification"],
  components: {
    CardDataComponent,
    VacationTab,
    AbsenceTab,
    PermissionListTab,
  },
  data() {
    return {
      tabs: [
        new Tab({ name: this.$t("pages.permission.absences.title"), key: PERMISSION_ABSENCE_TAB }),
        new Tab({
          name: this.$t("pages.permission.vacations.title"),
          key: PERMISSION_VACATION_TAB,
        }),
        new Tab({
          name: this.$t("pages.permission.permission-list.title"),
          key: PERMISSION_LIST_TAB,
        }),
      ],
      tabKey: "",
    };
  },
  computed: {
    tabChangedEvent() {
      return CARD_DATA_TAB_CHANGED;
    },
    ...mapGetters("auth", ["activeEmployeePermissions"]),
  },
  mounted() {
    if (window.location.hash) {
      this.tabKey = window.location.hash.replace("#", "");
    } else {
      this.tabKey = PERMISSION_ABSENCE_TAB;
    }
  },
  methods: {
    tabChanged(tab) {
      this.tabKey = tab.key;
    },
    isVacation(val) {
      return val === PERMISSION_VACATION_TAB;
    },
    isAbsence(val) {
      return val === PERMISSION_ABSENCE_TAB;
    },
    isPermissionTab(val) {
      return val === PERMISSION_LIST_TAB;
    },
  },
};
</script>
