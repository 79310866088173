<template>
  <card-info-component
    :title="$t('pages.planning.widgets.real_hours')"
    :value="value"
    :is-loading="isLoading"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";
import api from "@/api/user";

import { startOfMonth, endOfMonth } from "date-fns";

export default {
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      value: 0,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;

      const startDate = startOfMonth(new Date());
      const endDate = endOfMonth(new Date());
      api
        .getWorkHours({ from: startDate, to: endDate })
        .then((response) => {
          this.value = _.sumBy(response.data, (x) => x.dayApprovedHours);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
