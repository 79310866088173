<template>
  <article>
    <h2>{{ $t("pages.permission.data") }}</h2>
    <form class="permission-form mt-3">
      <suc-form-has-error :form="form" />

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <vacation-start-day-field
            id="vacation-start-date"
            name="dateStart"
            :form="form"
            :required="true"
          />
        </div>
        <div class="col-sm-12 col-md-6">
          <vacation-end-day-field
            id="vacation-end-date"
            name="dateEnd"
            :form="form"
            :required="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <vacation-comment-field id="vacation-comment" name="comments" :form="form" />
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          <vue-button
            :title="$t('pages.permission.vacations.send_request')"
            class="btn-primary send-button"
            :loading="isLoading"
            @click="sendRequest"
          />
        </div>
      </div>
    </form>
  </article>
</template>

<script>
import { SucFormHasError, Form, VueButton } from "@/components/form";
import { ModalAlert } from "@/components/modal";
import { RequiredRule, FormRule } from "@/components/form/data/rules";
import {
  VacationStartDayField,
  VacationEndDayField,
  VacationCommentField,
} from "@/pages/employee/permission/vacations/fields";

import api from "@/api/appointment";
import { isAfter, isEqual } from "date-fns";

import { VACATION_CREATED_EVENT } from "./index";

import { mapGetters, mapState } from "vuex";
import * as Sentry from "@sentry/vue";

export default {
  components: {
    VacationStartDayField,
    VacationEndDayField,
    VacationCommentField,
    VueButton,
    SucFormHasError,
  },
  data() {
    return {
      isLoading: false,
      form: new Form({
        data: {
          dateStart: null,
          dateEnd: null,
          comments: null,
        },
      }),
    };
  },
  computed: {
    ...mapGetters("auth", [
      "employeeCode",
      "isCurrentRoleEmployee",
      "isCurrentRoleResponsible",
      "holidaysAbsenceCode",
    ]),
    ...mapState("auth", ["auth"]),
    fieldsRules() {
      return [
        new RequiredRule({
          name: "dateStart",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
        new RequiredRule({
          name: "dateEnd",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];
    },
    formRules() {
      let checkDate = new FormRule({
        rule: (x) => {
          return isAfter(x.dateEnd, x.dateStart) || isEqual(x.dateEnd, x.dateStart);
        },
        errorMessage: this.$t("pages.permission.wrong_period_date"),
      });
      return [checkDate];
    },
  },
  methods: {
    clear() {
      this.form.reset();
    },
    prepareData() {
      let data = this.form.data();
      let request = new FormData();
      request.append("employeeCode", this.employeeCode);
      request.append("typeAppointment", this.holidaysAbsenceCode);
      request.append("dateEnd", data.dateEnd);
      request.append("dateStart", data.dateStart);
      if (this.$isset(data.comments)) {
        request.append("comments", data.comments);
      }
      return request;
    },
    sendRequest() {
      this.form.setFieldsRules(this.fieldsRules);
      this.form.setFormRules(this.formRules);
      if (this.form.validate()) {
        var data = this.prepareData();

        Sentry.setContext("sendData", { ...this.form.data(), employeeCode: this.employeeCode });

        this.isLoading = true;
        api
          .createAppointment(data)
          .then(() => {
            this.$toasts.success(this.$t("pages.permission.vacations.operation_completed"));
            EventBus.fire(VACATION_CREATED_EVENT);
            this.clear();
          })
          .catch((error) => {
            console.error(error.response);

            if (error.message.toLowerCase() === "network error") {
              ModalAlert.error({ message: this.$t("components.messages.network_error") });
            } else {
              //unsigned error
              Sentry.captureException(error);
              let errorMessage = this.$t("components.messages.error");
              if (error?.response?.data?.errors) {
                errorMessage = error.response.data.errors[""][0];
              }
              console.error(errorMessage);
              ModalAlert.error({ message: errorMessage });
            }
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.send-button {
  border-radius: $border-radius;
  height: 50px;
}
</style>
