<template>
  <card-info-component
    :title="$t('pages.planning.widgets.hour_balance')"
    :value="value"
    :is-loading="isLoading"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";
import { Employee } from "@/pages/components/data";

import api from "@/api/user";

export default {
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      value: 0,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;

      api
        .whoAmI()
        .then((response) => {
          this.value = new Employee().parse(response.data).hourBalance;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
