<template>
  <div>
    <div class="d-flex p-2 table-responsive-md">
      <vacation-confirm-awaiting-widget />
      <vacation-approved-widget class="ml-4" />
      <employee-vacation-day-widget class="ml-4" />
      <hour-balance-widget class="ml-4" v-if="activeHoursPack" />
    </div>

    <info-component
      :type="infoType"
      class="mt-5"
      :message="$t('pages.permission.vacations.info_message')"
    ></info-component>

    <div class="row mt-5">
      <div class="col-sm-12 col-md-7">
        <vacation-calendar-component></vacation-calendar-component>
      </div>
      <div class="col-sm-12 col-md-5">
        <vacation-create-form-component v-if="vacationCreate"></vacation-create-form-component>

        <vacation-edit-form-component
          v-if="vacationEdit"
          :vacation="vacation"
        ></vacation-edit-form-component>

        <vacation-show-modal />
      </div>
    </div>
  </div>
</template>

<script>
import {
  VacationConfirmAwaitingWidget,
  VacationApprovedWidget,
} from "@/pages/employee/permission/vacations/widgets";
import { HourBalanceWidget } from "@/pages/employee/planning/widgets";

import InfoComponent, { INFO_TYPE_INFO_COMPONENT } from "@/pages/components/info";
import { VACATION_SHOW_CREATE_EVENT, VACATION_SHOW_EDIT_EVENT } from "./components";

import {
  VacationCalendarComponent,
  VacationCreateFormComponent,
  VacationEditFormComponent,
  VacationShowModal,
} from "@/pages/employee/permission/vacations/components";
import EmployeeVacationDayWidget from "@/pages/dashboard/widgets/EmployeeVacationDayWidget.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    VacationCalendarComponent,
    InfoComponent,
    VacationShowModal,
    VacationConfirmAwaitingWidget,
    HourBalanceWidget,
    VacationApprovedWidget,
    VacationCreateFormComponent,
    VacationEditFormComponent,
    EmployeeVacationDayWidget,
  },

  created() {
    EventBus.listen(VACATION_SHOW_EDIT_EVENT, (vacation) => {
      this.vacation = vacation;
      this.showEdit();
    });
    EventBus.listen(VACATION_SHOW_CREATE_EVENT, (vacation) => {
      this.vacation = vacation;
      this.showCreate();
    });
  },
  beforeDestroy() {
    EventBus.off([VACATION_SHOW_EDIT_EVENT, VACATION_SHOW_CREATE_EVENT]);
  },
  data() {
    return {
      vacation: null,
      vacationEdit: false,
      vacationCreate: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack"]),
    infoType() {
      return INFO_TYPE_INFO_COMPONENT;
    },
  },
  methods: {
    showCreate() {
      this.vacationEdit = false;
      this.vacationCreate = true;
    },
    showEdit() {
      this.vacationEdit = true;
      this.vacationCreate = false;
    },
  },
};
</script>

<style></style>
