<template>
  <suc-calendar-component
    :type="type"
    :cellData="cellData"
    @[getCalendarDataEvent]="init"
    :isLoading="isLoading"
  >
    <template #cell="props">
      <suc-calendar-item-component :cell="props.cell">
        <template #body="props">
          <vacation-content-component :cell="props.cell" />
        </template>
      </suc-calendar-item-component>
    </template>
    <template #foot>
      <tr>
        <td colspan="7">
          <div class="row">
            <div class="col d-flex flex-wrap">
              <vacation-confirm-waiting-day-legend class="m-1" />
              <vacations-approved-day-legend class="m-1" />
              <vacations-need-meeting-day-legend class="m-1" />
            </div>
          </div>
        </td>
      </tr>
    </template>
  </suc-calendar-component>
</template>

<script>
import SucCalendarComponent, {
  SucCalendarItemComponent,
  GET_CALENDAR_DATA,
  CellData,
} from "@/components/calendar";
import { DATE_PERIOD_MONTH } from "@/components/form";
import VacationContentComponent from "./VacationContentComponent.vue";

import { addDays, differenceInCalendarDays, isEqual } from "date-fns";

import api from "@/api/appointment";
import { FinalAbsence } from "@/pages/components/data";

import {
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
} from "@/pages/components/day-legend";
import { VACATION_CREATED_EVENT, VACATION_UPDATED_EVENT } from "./index";

export default {
  components: {
    SucCalendarComponent,
    SucCalendarItemComponent,
    VacationContentComponent,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
  },
  created() {
    EventBus.listen([VACATION_CREATED_EVENT, VACATION_UPDATED_EVENT], () => {
      this.init({ start: this.dateStart, end: this.dateEnd });
    });
  },
  beforeDestroy() {
    EventBus.off([VACATION_CREATED_EVENT, VACATION_UPDATED_EVENT]);
  },
  data() {
    return {
      cellData: [],
      vacations: [],
      rawData: [],
      dateStart: null,
      dateEnd: null,
      isLoading: false,
    };
  },
  computed: {
    type() {
      return DATE_PERIOD_MONTH;
    },
    getCalendarDataEvent() {
      return GET_CALENDAR_DATA;
    },
  },
  methods: {
    async init({ start, end }) {
      this.isLoading = true;
      this.dateStart = start;
      this.dateEnd = end;
      await this.loadData(start, end);
      this.prepare();
      this.isLoading = false;
    },
    async loadData(start, end) {
      let vacations = [];
      const { data } = await api.getMyVacations({ start, end });
      for (let item of data) {
        vacations.push(new FinalAbsence().parse(item));
      }
      this.vacations = vacations;
      this.rawData = data;
    },
    prepare() {
      let data = [];
      for (let item of this.vacations) {
        const diffDays = differenceInCalendarDays(item.dateEnd, item.dateStart);
        for (let i = 0; i <= diffDays; i++) {
          let day = addDays(item.dateStart, i);
          day.setHours(0, 0, 0, 0);
          let cell = data.find((x) =>
            isEqual(new Date(x.day.getFullYear(), x.day.getMonth(), x.day.getDate()), day)
          );
          if (this.$isset(cell)) {
            cell.data.push(item);
          } else {
            data.push(new CellData({ day, data: [item] }));
          }
        }
      }
      this.cellData = data;
    },
  },
};
</script>

<style scoped></style>
