<template>
  <card-info-component
    :title="$t('pages.permission.vacations.rejected')"
    :value="value"
    :isLoading="isLoading"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";

import api from "@/api/appointment";
import { Appointment } from "@/pages/components/data";

import { startOfMonth, endOfMonth } from "date-fns";

export default {
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      items: [],
      raw: [],
      start: null,
      end: null,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    value() {
      return this.items.length;
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.start = startOfMonth(new Date());
      this.end = endOfMonth(new Date());

      const { data } = await api.getMyVacations({ start: this.start, end: this.end });
      this.items = data
        .map((x) => new Appointment().parse(x))
        .filter((x) => x.isVacationsRejected());
      this.raw = data;

      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
